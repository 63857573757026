import React, { useContext } from "react";

import SEO from "../components/SEO";
import LearnMore from "../components/partials/index/LearnMoreSection";
import Features from "../components/partials/schoolleader/FeaturesSection";
import SolidBGHeroSection from "@src/components/SolidBGHeroSection";
import FeaturesSection from "@src/components/partials/shared/FeaturesSection";
import SignUpCTASection from "@src/components/SignUpCTASection";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { graphql, useStaticQuery } from "gatsby";
import { theme } from "@src/components/nessie-web";

const {
  colors: { dt_aqua50 },
} = theme;

const IndexPage = () => {
  const t = useContext(TranslationContext);
  const data = useStaticQuery(graphql`
    {
      directus {
        page_teacherlp2 {
          feature_one_image {
            filename_disk
          }
          feature_two_image {
            filename_disk
          }
          feature_three_image {
            filename_disk
          }
        }
      }
    }
  `);

  const {
    directus: {
      page_teacherlp2: { feature_one_image, feature_two_image, feature_three_image },
    },
  } = data;

  const SolidBGHeroSectionProps = {
    title: "directus.page_teacherlp2.hero_title",
    subtitle: "directus.page_teacherlp2.hero_subtitle",
    sentence: "directus.page_teacherlp2.hero_text",
    BGColor: dt_aqua50,
    fullWidthButton: true,
  };

  const FeaturesSectionProps = {
    features: [
      {
        img: feature_one_image,
        title: t.translate("directus.page_teacherlp2.feature_one_title"),
        description: t.translate("directus.page_teacherlp2.feature_one_text"),
      },
      {
        img: feature_two_image,
        title: t.translate("directus.page_teacherlp2.feature_two_title"),
        description: t.translate("directus.page_teacherlp2.feature_two_text"),
      },
      {
        img: feature_three_image,
        title: t.translate("directus.page_teacherlp2.feature_three_title"),
        description: t.translate("directus.page_teacherlp2.feature_three_text"),
      },
    ],
  };

  const SignUpCTASectionProps = {
    fullWidth: true,
  };

  return (
    <>
      <SEO
        title="Remote Learning on ClassDojo"
        description="ClassDojo brings your school together, wherever you are, free"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Remote Learning on ClassDojo",
          description: "ClassDojo brings your school together, wherever you are, free",
        }}
      />
      <SolidBGHeroSection {...SolidBGHeroSectionProps} />
      <FeaturesSection contentData={FeaturesSectionProps} />
      <SignUpCTASection {...SignUpCTASectionProps} />
      <LearnMore hideCta />
      <Features />
    </>
  );
};
export default IndexPage;
